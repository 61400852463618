import {
  entityTypes,
  originTypes,
  reportTargetTypeOptions,
} from 'react/member/components/dashboard/reports/Constants';

export function getEntityTypeOptions({ origin, report, entityCollections }) {
  if (origin.type !== originTypes.organization) {
    return [];
  }

  const options = [];
  const { supportsSearchBy } = report;
  const exclusiveTrustType = supportsSearchBy.trustType;

  if (supportsSearchBy.organization) {
    options.push(reportTargetTypeOptions.organization);
  }

  const hasPooledTrusts = entityCollections[entityTypes.pooledTrust]?.length;
  if (!exclusiveTrustType || (exclusiveTrustType === entityTypes.pooledTrust && hasPooledTrusts)) {
    options.push(reportTargetTypeOptions.allPooledTrusts);

    if (supportsSearchBy.trust) {
      options.push(reportTargetTypeOptions.pooledTrust);
    }
  }

  const hasPortfolios = entityCollections[entityTypes.portfolio]?.length;
  if (supportsSearchBy.portfolio && hasPortfolios && hasPooledTrusts) {
    options.push(reportTargetTypeOptions.portfolio);
  }

  const hasStandaloneTrusts = entityCollections[entityTypes.standaloneTrust]?.length;
  if (
    !exclusiveTrustType ||
    (exclusiveTrustType === entityTypes.standaloneTrust && hasStandaloneTrusts)
  ) {
    options.push(reportTargetTypeOptions.allStandaloneTrusts);

    if (supportsSearchBy.trust) {
      options.push(reportTargetTypeOptions.standaloneTrust);
    }
  }

  const hasBeneficiaries = entityCollections[entityTypes.trustBeneficiary]?.length;
  if (supportsSearchBy.beneficiary && hasBeneficiaries) {
    options.push(reportTargetTypeOptions.trustBeneficiary);
  }

  return options;
}
